import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useState } from "react";
import FirmaAvanzadaModal from "./FirmaAvanzadaModal";

const FirmantesModal = ({
  open,
  handleClose,
  firmasElectronicas,
  firmasDigitales,
  estadoRevocacion,
}) => {
  const [firmaSeleccionada, setFirmaSeleccionada] = useState(null);
  const [avanzadaOpen, setAvanzadaOpen] = useState(false);

  const handleAvanzadaOpen = (firma) => {
    setFirmaSeleccionada(firma);
    setAvanzadaOpen(true);
  };

  const handleAvanzadaClose = () => {
    setAvanzadaOpen(false);
    setFirmaSeleccionada(null);
  };

  const renderFirmaDigital = (firma, index) => {
    return (
      <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
        >
          {firma.valida && estadoRevocacion === "OK" ? (
            <CheckCircleIcon sx={{ color: "green", marginRight: 1 }} />
          ) : firma.valida && estadoRevocacion === "NO_SE_PUEDE_COMPROBAR" ? (
            <WarningIcon sx={{ color: "orange", marginRight: 1 }} />
          ) : (
            <CancelIcon sx={{ color: "red", marginRight: 1 }} />
          )}
          <Typography variant="subtitle1">
            Rev. {firma.revision}: Firmado por {firma.nombreComun}{" "}
            {firma.cuit && ` - CUIT: ${firma.cuit}`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography>
              La firma es {firma.valida ? "válida" : "inválida"}
            </Typography>
            <Typography>
              No ha habido modificaciones en el documento desde que se firmó
            </Typography>
            <Typography>
              El certificado del firmante es{" "}
              {firma.valida
                ? "válido y no se ha revocado"
                : "inválido o se ha revocado"}
            </Typography>
            <Typography>
              La firma cubre todo el contenido del documento
            </Typography>
            <Typography>
              El documento posee un total de {firmasDigitales.length} revisiones
            </Typography>
            <Typography>
              Hora de firma: {new Date(firma.fechaFirma).toLocaleString()} -
              Sello de tiempo: {firma.fechaDeTimeStampServer ? "Sí" : "No"}
            </Typography>
            {/* <Typography>Motivo: {firma.razon}</Typography>
                        <Typography>Ubicación: {firma.localidad}</Typography> */}
            <Button
              variant="contained"
              onClick={() => handleAvanzadaOpen(firma)}
              sx={{
                bgcolor: "#356396",
                mt: 2,
                "&:hover": {
                  bgcolor: "#356396",
                },
                maxWidth: "250px",
                width: "100%",
              }}
            >
              Propiedades Avanzadas
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };
//comentario para hacer push
  const renderFirmaElectronica = (firma, index) => {
    return (
      <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
        >
          <Typography
            variant="subtitle1"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {firma.valida ? (
            <CheckCircleIcon sx={{ color: "green", marginRight: 1 }} />
          ) : (
            <CancelIcon sx={{ color: "red", marginRight: 1 }} />
          )}
            Firmado por {firma.nombre && `${firma.nombre}`} {firma.cuit && ` - CUIT: ${firma.cuit}`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {firma.valida ? (
              <>
                <Typography>La firma es válida</Typography>
                <Typography>
                  No ha habido modificaciones en el documento desde que se
                  firmó.
                </Typography>
              </>
            ) : (
              <>
                <Typography>La firma no es válida</Typography>
                <Typography>
                  El documento fue modificado luego de ser firmado.
                </Typography>
              </>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="firmantes-modal-title"
        aria-describedby="firmantes-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 0,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" id="firmantes-modal-title" gutterBottom>
            Panel de Firmas
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Firmas Electrónicas
          </Typography>
          {firmasElectronicas.length === 0 ? (
            <Typography>No hay firmas electrónicas</Typography>
          ) : (
            firmasElectronicas.map((firma, index) =>
              renderFirmaElectronica(firma, index)
            )
          )}
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Firmas Digitales
          </Typography>
          {firmasDigitales.length === 0 ? (
            <Typography>No hay firmas digitales</Typography>
          ) : (
            firmasDigitales.map((firma, index) =>
              renderFirmaDigital(firma, index)
            )
          )}
        </Box>
      </Modal>
      {firmaSeleccionada && (
        <FirmaAvanzadaModal
          open={avanzadaOpen}
          handleClose={handleAvanzadaClose}
          firma={firmaSeleccionada}
        />
      )}
    </>
  );
};

export default FirmantesModal;
