import '@peculiar/certificates-viewer/dist/peculiar/peculiar.css';
import { PeculiarCertificateViewer } from '@peculiar/certificates-viewer-react';

const CertificadoFirmante = ({ firma }) => {
  
  const certificado = firma?.certificadoFirma?.encoded || '';
  return (
    <div style={{ border: "1px solid #ccc", padding: "16px", borderRadius: "8px" }}>
       {certificado ? (
        <PeculiarCertificateViewer certificate={certificado} />
      ) : (
        <p>No se encontró un certificado válido para mostrar.</p>
      )}
    </div>
  );
};

export default CertificadoFirmante;
