import Typography from '@mui/material/Typography';
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { searchPlugin } from "@react-pdf-viewer/search";
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import React, { useState } from "react";
import "../css/PdfViewer.css";

export default function PdfViewer({ fileUrl }) {
  const searchPluginInstance = searchPlugin();
  const [error, setError] = useState(null);
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const handleViewerError = (err) => {
    setError(err);
  };

  return (
    <div className="pdfContainer">
      {fileUrl ? (
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
          <div className="viewerAndToolbarContainer">
            <div className="viewerContainer">
              <Viewer
                fileUrl={fileUrl}
                plugins={[toolbarPluginInstance, searchPluginInstance]}
                onError={handleViewerError}
              />
            </div>
            <div className="toolbarContainer">
              <Toolbar>
                {(props) => {
                  const {
                    CurrentPageInput,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    ZoomIn,
                    ZoomOut,
                  } = props;
                  return (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <CurrentPageInput />
                      <NumberOfPages />
                      <GoToPreviousPage />
                      <GoToNextPage />
                      <ZoomOut />
                      <ZoomIn />
                    </div>
                  );
                }}
              </Toolbar>
            </div>
          </div>
        </Worker>
      ) : (
        <Typography variant="body1" sx={{ color: 'gray' }}>
          No se ha seleccionado ningún archivo PDF.
        </Typography>
      )}

      {error && (
        <div className="errorMessage">
          EF005 - Hubo un error al cargar el visor PDF: {error.message}
        </div>
      )}
    </div>
  );
}
