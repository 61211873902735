import {
  AppBar,
  Box,
  Button,
  Card,
  CircularProgress,
  Snackbar,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { useState } from "react";
import "./App.css";
import documentosFirmadosCursor from "./assets/documentosFirmadosCursor.svg";
import logo from "./assets/logo.png";
import marcaNormal from "./assets/marcaNormal.svg";
import subirArchivo from "./assets/subirArchivo.svg";
import PdfModal from "./components/PdfModal";

function App() {
  const [file, setFile] = useState(null);
  // eslint-disable-next-line
  const [base64String, setBase64String] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [validationResult, setValidationResult] = useState(null);

  const handleFileChange = (event) => {
    handleFileUpload(event.target.files);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleFileUpload(event.dataTransfer.files);
  };

  const handleFileUpload = (files) => {
    const uploadedFile = files[0];
    if (uploadedFile && uploadedFile.type === "application/pdf") {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(",")[1];
        setBase64String(base64Data); // Guardamos el valor en el estado
        setLoading(true);
        handleSubmit(base64Data, uploadedFile);
      };
      reader.readAsDataURL(uploadedFile);
    } else {
      setFile(null);
      setOpen(false);
      setError("EF001 - Solo se pueden subir archivos PDF");
    }
  };

  const handleClose = () => {
    setFile(null);
    setOpen(false);
    setError("");
    setValidationResult(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = (base64String, uploadedFile) => {
    const data = {
      docBase64: base64String,
    };

    const url = `${process.env.REACT_APP_VALIDATE_FILES}/${process.env.REACT_APP_VALIDAR}`;
    setLoading(true);
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        const { resultsElectronicas, resultsDigitales, fechaInicio, fechaFin, firmaPlataforma } = response.data;

        // Verificar si la respuesta está vacía o sin datos útiles
        if (
          (!resultsElectronicas || resultsElectronicas.length === 0) &&
          (!resultsDigitales || resultsDigitales.length === 0) &&
          fechaInicio === null &&
          fechaFin === null &&
          firmaPlataforma === false
        ) {
          setSnackbarMessage("EF005 - El documento no contiene información válida.");
          setSnackbarOpen(true);
          setLoading(false);
          return;
        }
        console.log(response.data);
        setValidationResult(response.data);
        setFile(URL.createObjectURL(uploadedFile));
        setOpen(true);
        setError("");
      })
      .catch((error) => {
        console.error("Hubo un error:", error);
        if (error.response) {
          // Mensaje específico del servidor si responde con un error
          const errorMessage = `${error.response.data.errorMsg}`;
          setSnackbarMessage(
            errorMessage ||
              "EF002 - Ocurrió un error desconocido. Inténtalo más tarde o contacta a soporte."
          );
        } else if (error.request) {
          // Si el request se realizó pero no hubo respuesta, muestra un mensaje amigable en lugar de `error.request`
          setSnackbarMessage(
            "EF003 - No se recibió respuesta del servidor. Inténtalo más tarde."
          );
        } else {
          // Para otros errores de configuración, muestra un mensaje genérico
          setSnackbarMessage(
            "EF004 - Hubo un problema al configurar la solicitud. Inténtalo más tarde."
          );
        }

        setSnackbarOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className="App">
      <AppBar
        position="static"
        color="default"
        sx={{
          boxShadow: "none",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "white",
            width: "90%",
          }}
        >
          <Box
            component="img"
            src={marcaNormal}
            alt="Marca Normal"
            sx={{ height: 40 }}
          />
          <Box component="img" src={logo} alt="Logo" sx={{ height: 40 }} />
        </Toolbar>
      </AppBar>
      <MainContent
        handleFileChange={handleFileChange}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        loading={loading}
        validationResult={validationResult}
      />
      {(error || snackbarMessage) && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            sx={{ color: "white !important" }}
            onClose={handleSnackbarClose}
            severity="error"
          >
            {snackbarMessage ||
              error ||
              "EF002 - Ocurrió un error desconocido. Inténtalo más tarde o contacta a soporte."}
          </MuiAlert>
        </Snackbar>
      )}
      <PdfModal
        file={file}
        open={open}
        handleClose={handleClose}
        validationResult={validationResult}
      />
      {/* <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      /> */}
    </div>
  );
}

function MainContent({
  handleFileChange,
  handleDrop,
  handleDragOver,
  loading,
  validationResult,
}) {
  return (
    <Box
      className="MainContent"
      sx={{
        flex: 1,
        backgroundColor: "#356396",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div
        style={{ display: "flex", alignItems: "flex-start", height: "70px" }}
      >
        <Box
          component="img"
          src={documentosFirmadosCursor}
          alt="Upload"
          sx={{ maxWidth: "25px" }}
        />
        <Typography
          variant="body1"
          sx={{ color: "white", fontSize: "20px", marginLeft: "20px" }}
        >
          VALIDAR DOCUMENTO
        </Typography>
      </div>
      <Card
        sx={{
          height: "calc(80% - 70px)",
          width: {
            xs: "70%", // Para pantallas pequeñas (mobile)
            md: "80%", // Para pantallas medianas y más grandes (desktop)
          },
          backgroundColor: "white",
          borderRadius: 0,
          padding: 4,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <CircularProgress sx={{ color: "#356396" }} />
        ) : (
          <>
            {validationResult ? (
              <>{/* Renderiza el resultado de la validación aquí */}</>
            ) : (
              <>
                <Box
                  component="img"
                  src={subirArchivo}
                  alt="Upload"
                  sx={{ height: 100, marginBottom: 2 }}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{ backgroundColor: "#356396", width: "200px" }}
                >
                  Subir
                  <input
                    type="file"
                    hidden
                    accept="application/pdf"
                    onChange={handleFileChange}
                  />
                </Button>
                <Typography
                  variant="body1"
                  sx={{ marginTop: 2, color: "gray" }}
                >
                  Seleccione un archivo o arrastre un archivo
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", marginTop: 1 }}
                >
                  Solo puede ser PDF
                </Typography>
              </>
            )}
          </>
        )}
      </Card>
    </Box>
  );
}

export default App;
